import { TAvatarProps } from '../Avatar.type'
import Image from 'next/image'
import { cx } from 'class-variance-authority'
import styles from '../Avatar.module.css'

function Avatar(props: TAvatarProps) {
  const { src, size, className, online, shape, ...rest } = props

  return (
    <span className={cx(styles.mainContainer, className)} data-size={size} {...rest}>
      <div className={styles.avatarContainer}>
        <div className={styles.onlineStatus} data-online={online} />
        <div
          className={styles.avatar}
          data-shape={shape ?? 'circle'}
          data-avatar-variant={'url' in src ? 'image' : 'text'}
        >
          {'text' in src && (
            <div className={styles.iconText}>
              {src.text
                .split(' ')
                .map((word) => word[0])
                .slice(0, 3)
                .join('')
                .toUpperCase()}
            </div>
          )}
          {'url' in src && <Image src={src.url} className={styles.image} fill={true} alt="avatar" />}
        </div>
      </div>
    </span>
  )
}

export default Avatar
