import { NotificationModal, useNotificationByFeed } from "notifications"
import { useState } from "react"
import { Dropdown } from "shared-ui"

import IconNav from "./IconNav"

type NotificationSectionProps = {
  isBendahara?: boolean
}

const NotificationSection = ({
  isBendahara = false,
}: NotificationSectionProps) => {
  const { unseenCount, isNotificationEmpty } = useNotificationByFeed(undefined)
  const [openModalNotification, setOpenModalNotification] = useState(false)

  const handleModalNotification = (open: boolean) => {
    setOpenModalNotification(open)
  }

  return (
    <>
      <Dropdown
        trigger={
          <div
            className="relative cursor-pointer"
            onClick={() => setOpenModalNotification(!openModalNotification)}
          >
            <IconNav
              nameIcon="Bell"
              countNotification={
                !isNotificationEmpty && !!unseenCount
                  ? unseenCount >= 10
                    ? "9+"
                    : unseenCount
                  : 0
              }
            />
          </div>
        }
        onOpenChange={() => handleModalNotification(false)}
        open={openModalNotification}
      >
        <NotificationModal
          isOpen={openModalNotification}
          isBendahara={isBendahara}
          onClose={() => handleModalNotification(false)}
        />
      </Dropdown>
    </>
  )
}

export default NotificationSection
