import jwtDecode from "jwt-decode"
import type { Session } from "next-auth"
import { User } from "react-feather"
import { Spinner } from "shared-ui"

import {
  useGetInstitutionById,
  useGetPersonaNonPenyediaById,
  useGetApplicationByFilter,
  useGetPersonaListForApplication,
} from "../hooks/useGetAccountInfo"
import { signIn } from "@/authentication/authService"
import { type TDecodeJwt } from "@/authentication/types"
import { ApplicationTypeEnum } from "@/federatedGql/graphql"

type TAccountInfoProps = {
  session: Session
}

const AccountInfo = ({ session }: TAccountInfoProps) => {
  const accessToken = session?.token?.accessToken || ""
  const decodeToken: TDecodeJwt = jwtDecode(accessToken)
  const insitutionId = decodeToken?.gtp_persona?.institutionId || ""
  const personaId = decodeToken?.gtp_persona?.personaId || ""

  const { data: institution, isFetching: isFetchingInstitution } =
    useGetInstitutionById({
      variables: {
        id: insitutionId,
      },
      enabled: Boolean(insitutionId),
    })

  const { data: persona, isFetching: isFetchingPersona } =
    useGetPersonaNonPenyediaById({
      variables: {
        id: personaId,
      },
      enabled: Boolean(personaId),
    })

  const { data: applicationList, isFetching: isFetchingApplicationList } =
    useGetApplicationByFilter({
      variables: {
        filter: {
          applicationTypeNames: [ApplicationTypeEnum.EKatalogV6Buyer],
        },
      },
      enabled: Boolean(personaId),
    })

  const appId = applicationList?.[0]?.id

  const { data: personaAppList, isFetching: isFetchingPersonaAppList } =
    useGetPersonaListForApplication({
      variables: {
        appId: appId || "",
      },
      enabled: Boolean(appId),
    })

  const hasMultiplePersona = personaAppList && (personaAppList.length || 0) > 1

  const accountInfo = [
    {
      title: "Instansi",
      value: persona?.klpd?.namaKlpd || "-",
    },
    {
      title: "Satker",
      value:
        `${institution?.satker?.kodeSatker} - ${institution?.satker?.namaSatker}` ||
        "-",
    },
    {
      title: "Unit",
      value: persona?.persona?.unit || "-",
    },
  ]

  const handleChangeAccess = () => {
    signIn("auth0")
  }

  return (
    <div>
      {isFetchingInstitution || isFetchingPersona ? (
        <div className="flex w-full gap-2 border-b border-tertiary50 p-5">
          <Spinner color="primary" size="small" />
        </div>
      ) : (
        <div className="flex w-full flex-col gap-2 border-b border-tertiary50 p-5">
          {accountInfo.map((item, idx) => {
            return (
              <div key={`profile-info-${idx}`} className="flex w-full flex-col">
                <div className="text-sm text-tertiary300">{item.title}</div>
                <div className="font-semibold text-tertiary500">
                  {item.value}
                </div>
              </div>
            )
          })}
        </div>
      )}

      {!isFetchingApplicationList &&
        !isFetchingPersonaAppList &&
        hasMultiplePersona && (
          <div
            className="flex w-full cursor-pointer justify-start gap-2 border-b border-tertiary50 p-4 pl-5"
            onClick={handleChangeAccess}
          >
            <div className="flex w-full justify-start gap-2">
              <User size={20} />
              <div className="text-sm hover:underline">Ganti Akses</div>
            </div>
          </div>
        )}
    </div>
  )
}

export default AccountInfo
