import { Plus } from "react-feather"
import { Button } from "shared-ui"

type TAddAddressButtonProps = {
  onClick?: () => void
}
const AddAddressButton = ({ onClick }: TAddAddressButtonProps) => {
  return (
    <Button
      id="header-navbar-add-address-btn"
      variant="outline2"
      color="tertiary"
      IconLeft={Plus}
      style={{
        paddingRight: "12px !important",
        backgroundColor: "white",
        border: "1px solid #E2E4E8",
      }}
      onClick={onClick}
    >
      Tambah Alamat Baru
    </Button>
  )
}

export default AddAddressButton
