import { graphql } from "@/federatedGql"

export const GET_AUTO_COMPLETE_PRODUCT = graphql(`
  query getAutoCompleteProduct($keyword: String!) {
    autoCompleteProduct(keyword: $keyword) {
      ... on AutoCompleteProductResponse {
        __typename
        items {
          text
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_LIST_PERSONA_NON_PENYEDIA = graphql(`
  query getListPersonaNonPenyedia($filter: ListPersonaFilterInput) {
    listPersonaNonPenyedia(filter: $filter) {
      ... on ListPersonaNonPenyedia {
        __typename
        persona {
          appId
          id
          institusiId
          institusiMemberId
          role
          satkerKodeAdj
          satkerName
          userRoleId
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_INSTITUTION_BY_ID = graphql(`
  query getInstitutionById($id: String!) {
    getInstitutionByID(id: $id) {
      ... on Institution {
        __typename
        company {
          bentukUsaha
          bidangIndustri
          id
          name
          status
          isUmkk
          slug
          companyAddresses {
            fullAddress
            isMainAddress
            label
            latitude
            longitude
            notes
            postalCode
            villageAreaCode
            phoneNumber
          }
          shippingAddresses {
            villageAreaCode
            receiverName
            postalCode
            phoneNumber
            notes
            longitude
            latitude
            label
            isMainAddress
            id
            fullAddress
            regionDetail {
              cityName
            }
          }
        }
        institution {
          id
          institutionType
        }
        klpd {
          jenisKlpd
          kodeKlpd
          namaKlpd
          namaKlpdEcatalog
          namaKlpdPadi
          namaKlpdSakti
          namaKlpdSipd
          namaKlpdSirup
        }
        satker {
          institutionId
          jenisSatker
          kodeSatker
          namaSatker
          namaSatkerEcatalog
          namaSatkerPadi
          namaSatkerSakti
          namaSatkerSipd
          namaSatkerSirup
          namaSatkerTrx
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_APPLICATION_BY_FILTER = graphql(`
  query getApplicationByFilter($filter: ApplicationFilterInput) {
    getApplicationByFilter(filter: $filter) {
      ... on ApplicationList {
        __typename
        applications {
          appTypeId
          auth0ApplicationType
          description
          id
          lpseId
          name
          url
          updatedBy
          updatedAt
          createdAt
          createdBy
        }
      }
      ... on GenericError {
        __typename
        reqId
        code
        message
      }
    }
  }
`)

export const GET_PERSONA_LIST_FOR_APPLICATION = graphql(`
  query getPersonaListForApplication($appId: String!) {
    getPersonaListForApplication(appId: $appId) {
      ... on GetPersonaListForApplicationResult {
        __typename
        persona {
          appRole
          institutionName
          personaId
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_UNREAD_CHAT_COUNT = graphql(`
  query unreadChatCount {
    unreadChatCount {
      ... on UnreadChatCountResp {
        count
      }
      ... on GenericError {
        reqId
        code
        message
      }
    }
  }
`)
