import { getUserInfo, getExpiredTimeToken } from "shared-utils/authentication"

/**
 * Function Note:
 * This function is used to retrieve the login status without loading / flicker after login.
 */

const loginStatus = (isSSRLoginStatus: boolean) => {
  const userId = getUserInfo()?.user?.id
  const expiredTimeToken = getExpiredTimeToken
  if ((!userId && isSSRLoginStatus && expiredTimeToken) || !isSSRLoginStatus) {
    return "unauthenticated"
  }
  return "authenticated"
}

export default loginStatus
