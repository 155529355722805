import { cx } from "class-variance-authority"
import Image, { type ImageProps, type StaticImageData } from "next/image"
import LogoKatalogElektronik from "shared-assets/icons/logo-katalog-elektronik.svg"

type THeaderLogoImageProps = Omit<ImageProps, "src" | "alt">

const HeaderLogoImage = ({ ...props }: THeaderLogoImageProps) => {
  return (
    <Image
      {...props}
      className={cx("max-w-none", props.className)}
      data-test="header-logo"
      src={LogoKatalogElektronik as StaticImageData}
      alt="katalog elektronik logo"
    />
  )
}

export default HeaderLogoImage
