import Link from "next/link"

import ContentIconNav from "./ContentIconNav"
import type { IIconNavProps } from "../type"

const IconNav = ({
  nameIcon,
  countNotification,
  pathname,
  onClick,
}: IIconNavProps) => {
  if (pathname) {
    return (
      <Link
        href={pathname || ""}
        className="relative cursor-pointer"
        data-test={`icon-nav-${nameIcon}`}
      >
        {ContentIconNav({ nameIcon, countNotification })}
      </Link>
    )
  }
  return (
    <div
      onClick={onClick}
      className="relative cursor-pointer"
      data-test={`icon-nav-${nameIcon}`}
    >
      {ContentIconNav({ nameIcon, countNotification })}
    </div>
  )
}

export default IconNav
