import { cx } from "class-variance-authority"
import React, { useState } from "react"
import { ChevronDown } from "react-feather"

import useClickOutside from "@/utils/useClickOutside"

type DialogConfirmPropTypes = {
  children: React.ReactNode
  trigger: React.ReactNode
}

const DropdownMenu = ({ children, trigger }: DialogConfirmPropTypes) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const innerRef = useClickOutside({
    callback: () => {
      isOpen && setIsOpen(false)
    },
  })

  const handleSetOpen = () => {
    setIsOpen((data) => !data)
  }

  return (
    <div className="relative inline-block" ref={innerRef}>
      <div className="cursor-pointer" onClick={handleSetOpen}>
        <div
          className="mt-1 flex h-12 w-22 cursor-pointer items-center gap-2 sm:w-24"
          data-test="header-userInfoSection"
        >
          {trigger}

          <div data-test="header-dropdown-button" className="w-5">
            <ChevronDown className={cx(isOpen && "rotate-180")} />
          </div>
        </div>
      </div>
      <div
        className={cx("absolute rounded-2 bg-primary25 shadow-4 ", {
          block: isOpen,
          hidden: !isOpen,
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default DropdownMenu
