import { useEffect, useRef } from "react"

type TCallback = (e: MouseEvent) => void

type TUseClickOutside = {
  callback: TCallback
}

const useClickOutside = ({ callback }: TUseClickOutside) => {
  const innerRef = useRef<HTMLDivElement>(null)
  const callbackRef = useRef<TCallback>()

  useEffect(() => {
    callbackRef.current = callback
  })

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target as Node)
      ) {
        callbackRef.current(e)
      }
    }
    document.addEventListener("click", handleClick)
    return () => document.removeEventListener("click", handleClick)
  }, [])

  return innerRef
}

export default useClickOutside
