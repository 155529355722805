import React from "react"
import * as Icons from "react-feather"

export type IconName = keyof typeof Icons

export type IconProps = {
  name: IconName
  color?: string
} & Icons.IconProps
export function DynamicIcon({ name, color, ...rest }: IconProps) {
  const IconComponent = Icons[name]
  return (
    <IconComponent className={color ? `text-${color}` : undefined} {...rest} />
  )
}
