import { DynamicIcon } from "./DynamicIcon"
import type { IIconNavProps } from "../type"

const ContentIconNav = ({ nameIcon, countNotification }: IIconNavProps) => {
  return (
    <>
      <DynamicIcon
        name={nameIcon}
        size={24}
        color="tertiary400"
        strokeWidth={2.5}
      />
      {Boolean(countNotification) && (
        <div
          className="absolute -right-1 -top-2 rounded-full border-2 border-primary25 bg-primary400 px-1 text-center text-xs text-primary25"
          style={{ height: "21px", minWidth: "21px" }}
        >
          {countNotification}
        </div>
      )}
    </>
  )
}

export default ContentIconNav
