import { createQuery } from "react-query-kit"

import {
  GET_INSTITUTION_BY_ID,
  GET_LIST_PERSONA_NON_PENYEDIA,
  GET_APPLICATION_BY_FILTER,
  GET_PERSONA_LIST_FOR_APPLICATION,
} from "../federated/queries"
import {
  type GetInstitutionByIdQuery,
  type GetInstitutionByIdQueryVariables,
  type GetPersonaNonpenyediaByIdQuery,
  type GetPersonaNonpenyediaByIdQueryVariables,
  type GetListPersonaNonPenyediaQuery,
  type GetListPersonaNonPenyediaQueryVariables,
  type GetApplicationByFilterQueryVariables,
  type GetPersonaListForApplicationQueryVariables,
  type Application,
  type PersonaListData,
} from "@/federatedGql/graphql"
import { GET_PERSONA_NON_PENYEDIA } from "@/hooks/address/federated/queries"
import { federatedGqlClient } from "@/utils/graphqlClient"

type TGetInstitutionByIdResponse = Extract<
  GetInstitutionByIdQuery["getInstitutionByID"],
  { __typename: "Institution" }
>

type TGetPersonaNonPenyediaByIdResponse = Extract<
  GetPersonaNonpenyediaByIdQuery["getPersonaNonpenyediaByID"],
  { __typename: "PersonaNonPenyedia" }
>

type TGetListPersonaNonPenyediaResponse = Extract<
  GetListPersonaNonPenyediaQuery["listPersonaNonPenyedia"],
  { __typename: "ListPersonaNonPenyedia" }
>

export const useGetInstitutionById = createQuery<
  TGetInstitutionByIdResponse,
  GetInstitutionByIdQueryVariables
>({
  primaryKey: "getInstitutionById",
  queryFn: async ({ queryKey: [, variables] }) => {
    const res = await federatedGqlClient.request(
      GET_INSTITUTION_BY_ID,
      variables
    )
    if (res?.getInstitutionByID?.__typename === "GenericError") {
      throw new Error(res.getInstitutionByID.message || "Error")
    }
    return res.getInstitutionByID
  },
})

export const useGetPersonaNonPenyediaById = createQuery<
  TGetPersonaNonPenyediaByIdResponse,
  GetPersonaNonpenyediaByIdQueryVariables
>({
  primaryKey: "getPersonaNonPenyediaById",
  queryFn: async ({ queryKey: [, variables] }) => {
    const res = await federatedGqlClient.request(
      GET_PERSONA_NON_PENYEDIA,
      variables
    )
    if (res.getPersonaNonpenyediaByID.__typename === "GenericError") {
      throw new Error(res.getPersonaNonpenyediaByID.message || "Error")
    }
    return res.getPersonaNonpenyediaByID
  },
})

export const useGetListPersonaNonPenyedia = createQuery<
  TGetListPersonaNonPenyediaResponse,
  GetListPersonaNonPenyediaQueryVariables
>({
  primaryKey: "getListPersonaNonPenyedia",
  queryFn: async ({ queryKey: [, variables] }) => {
    const res = await federatedGqlClient.request(
      GET_LIST_PERSONA_NON_PENYEDIA,
      variables
    )
    if (res?.listPersonaNonPenyedia.__typename === "GenericError") {
      throw new Error(res.listPersonaNonPenyedia.message || "Error")
    }
    return res.listPersonaNonPenyedia
  },
})

export const useGetApplicationByFilter = createQuery<
  Application[] | undefined,
  GetApplicationByFilterQueryVariables
>({
  primaryKey: "getApplicationByFilter",
  queryFn: async ({ queryKey: [, variables] }) => {
    const res = await federatedGqlClient.request(
      GET_APPLICATION_BY_FILTER,
      variables
    )
    if (res?.getApplicationByFilter?.__typename === "GenericError") {
      throw new Error(res.getApplicationByFilter.message || "Error")
    }
    return res.getApplicationByFilter?.applications as Application[]
  },
})

export const useGetPersonaListForApplication = createQuery<
  PersonaListData[] | undefined,
  GetPersonaListForApplicationQueryVariables
>({
  primaryKey: "getPersonaListForApplication",
  queryFn: async ({ queryKey: [, variables] }) => {
    const res = await federatedGqlClient.request(
      GET_PERSONA_LIST_FOR_APPLICATION,
      variables
    )
    if (res?.getPersonaListForApplication?.__typename === "GenericError") {
      throw new Error(res.getPersonaListForApplication.message || "Error")
    }
    return res.getPersonaListForApplication?.persona as PersonaListData[]
  },
})
