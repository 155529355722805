import { Spinner } from "shared-ui"

const LoadingSpinner = () => {
  return (
    <div className="mt-5 flex h-24 w-full items-center justify-center">
      <Spinner color="primary" size="medium" />
    </div>
  )
}

export default LoadingSpinner
