import { USER_ROLE } from "@/types/type"

const checkIsBendahara = (role?: string) => {
  if (!role) {
    return false
  }
  const isBendahara = [USER_ROLE.BENDAHARA, USER_ROLE.BENDAHARA_UD].includes(
    role
  )
  return isBendahara
}

export default checkIsBendahara
